import React from 'react';
import PropTypes from 'prop-types';
import {
  FiMaximize,
  FiSettings,
  FiCpu,
  FiTarget,
  FiAward,
  FiLayers,
  FiCalendar,
  FiTrendingUp,
  FiBarChart2
} from 'react-icons/fi';

import { Header, Container } from './benefits.css';
import Title from 'components/title';
import Text from 'components/utilities/text';

const iconSwitch = icon => {
  switch (icon) {
    case 'FiMaximize':
      return <FiMaximize size="32px" color="573bee" className="my-1" />;
    case 'FiSettings':
      return <FiSettings size="32px" color="573bee" className="my-1" />;
    case 'FiCpu':
      return <FiCpu size="32px" color="573bee" className="my-1" />;
    case 'FiTarget':
      return <FiTarget size="32px" color="573bee" className="my-1" />;
    case 'FiAward':
      return <FiAward size="32px" color="573bee" className="my-1" />;
    case 'FiLayers':
      return <FiLayers size="32px" color="573bee" className="my-1" />;
    case 'FiCalendar':
      return <FiCalendar size="32px" color="573bee" className="my-1" />;
    case 'FiTrendingUp':
      return <FiTrendingUp size="32px" color="573bee" className="my-1" />;
    case 'FiBarChart2':
      return <FiBarChart2 size="32px" color="573bee" className="my-1" />;
    default:
      return null;
  }
};

const Benefits = ({ benefit, columns = 2 }) => {
  const { title, content, benefits } = benefit;
  return (
    <>
      <Header>
        <Title as="h2" className="text-center">{title}</Title>
        <Text as="p" className="text-center">{content}</Text>
      </Header>
      <Container columns={columns}>
        {benefits.map(benefit => {
          const { icon, headline, text } = benefit;
          return (
            <div key={headline}>
              <div className="row mx-4">
                <div className="col-1 text-center mb-3 mr-3">
                  {iconSwitch(icon)}
                </div>
                <div className="col-11">
                  <div className="d-flex flex-column">
                    <h3 className="mb-4">{headline}</h3>
                    <p>{text}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Container>
    </>
  );
};

Benefits.propTypes = {
  benefits: PropTypes.object.isRequired
};

export default Benefits;
