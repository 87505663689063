import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './card.css';

const Card = ({ children, className }) => <Container className={className}>{children}</Container>;

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
