import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';

import Title from 'components/title';

import { Header, Container, Card } from './faqs.css';

const Faqs = ({ faqs }) => {
  const { title, faqs: faqss } = faqs;
  const [active, setActive] = useState(null);

  const toggleClass = clicked => {
    setActive(active === clicked ? null : clicked);
  };

  const moreLess = clicked => {
    return active === clicked ? (
      <div className="text-right">
        <FiChevronDown size="24px" />
      </div>
    ) : (
      <div className="text-right">
        <FiChevronRight size="24px" />
      </div>
    );
  };

  const renderList = list => {
    return list.map((l, i) => {
      return (
        <Card
          isActive={active === i}
          key={i}
          role="button"
          tabIndex="0"
          onKeyDown={() => toggleClass(i)}
          onClick={() => toggleClass(i)}
        >
          <div className="d-flex flex-row align-items-center justify-content-between">
            <h3 className="mb-0">{l.title}</h3>
            {moreLess(i)}
          </div>
          {active === i && <p>{l.content}</p>}
        </Card>
      );
    });
  };

  return (
    <>
      <Header>
        <Title as="h2" className="text-center">{title}</Title>
      </Header>
      <Container>{renderList(faqss)}</Container>
    </>
  );
};

Faqs.propTypes = {
  faqs: PropTypes.array.isRequired,
};

export default Faqs;
