import styled from 'styled-components';

export const Container = styled.div`
  margin: 50px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: 144px 144px 144px 144px 144px 144px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    padding: 0 24px;
  }
`;
