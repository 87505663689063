import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title } from './item.css';

const Item = ({ title, image }) => (
  <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
);

Item.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;
