import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${ props => props.columns === 3 ? "1fr 1fr 1fr" : "1fr 1fr" };
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  justify-content: center;
  margin: 50px auto 100px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  p {
    margin-bottom: 0;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #000000;
  }
`;

export const Header = styled.div`
  margin: 50px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
