import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  padding: 24px;
  background: #FFFFFF;
  border-radius: 3px;
  /* box-shadow: 0 5px 5px 0 rgba(154,160,185,.05), 0 5px 30px 0 rgba(166,173,201,.22); */
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
  overflow: hidden;
  @media (max-width: 768px) {
    width: auto;
    margin: 0 24px;
    border-radius: 0;
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #5b5e6d;
  }
  h5 {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    color: #5b5e6d;
    margin-bottom: 0;
  }
  p {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
  }
  img {
    width: 100%!important;
    margin: 0 auto;
    object-fit: cover;
  }
`;