import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  justify-content: center;
  margin: 50px auto 100px auto;
  max-width: 1140px;

  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;

    color: #000000;
  }
  p {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #0a171c;
  }
`;

export const Header = styled.div`
  margin: 50px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const Card = styled.div`
  box-sizing: border-box;
  min-height: 60px;
  padding: 18px 90px 16px 90px;
  background:  ${ props => props.isActive ? "#227fa0" : "#ffffff" };
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-right: 24px;
    margin-left: 24px;
    padding: 16px;
  }
  &:hover {
    background: #227fa0;
    h3,
    h5,
    p,
    svg {
      color: #ffffff;
    }
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${ props => props.isActive ? "#ffffff" : "#5b5e6d" };
  }
  p {
    padding-top: 40px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${ props => props.isActive ? "#ffffff" : "#333333" };
  }
  img {
    width: 100%!important;
    margin: 0 auto;
    object-fit: cover;
  }
  svg {
    color: ${ props => props.isActive ? "#ffffff" : "#333333" };
  }
`;