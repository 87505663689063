import React from 'react';
import PropTypes from 'prop-types';
import { Container, List } from './platforms.css';
import Item from './item';

const Platforms = ({ items }) => {
  return (
    <Container>
      <List>
        {items.map((item, i) => {
          return (
            <Item {...item} key={i} />
          )
        })}
      </List>
    </Container>
  )
}

Platforms.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Platforms;
