import React from 'react';
import PropTypes from 'prop-types';
import {
  FiUploadCloud,
  FiAnchor,
  FiStar,
  FiTrello,
  FiMaximize2,
  FiMonitor,
  FiSmartphone,
  FiTablet
} from 'react-icons/fi';


import Card from 'components/card';
import Title from 'components/title';
import Text from 'components/utilities/text';

import { Header, Container, IconWrapper } from './reasons.css';

const iconSwitch = icon => {
  switch (icon) {
    case 'FiUploadCloud':
      return <FiUploadCloud color="#ffffff" size="40px" />;
    case 'FiAnchor':
      return <FiAnchor color="#ffffff" size="40px" />;
    case 'FiStar':
      return <FiStar color="#ffffff" size="40px" />;
    case 'FiTrello':
      return <FiTrello color="#ffffff" size="40px" />;
    case 'FiMaximize2':
      return <FiMaximize2 color="#ffffff" size="40px" />;
    case 'FiMonitor':
      return <FiMonitor color="#ffffff" size="40px" />;
    case 'FiSmartphone':
      return <FiSmartphone color="#ffffff" size="40px" />;
    case 'FiTablet':
      return <FiTablet color="#ffffff" size="40px" />;
    default:
      return null;
  }
};

const Reasons = ({ reason }) => {
  const { title, content, reasons } = reason;
  return (
    <>
      <Header>
        <Title as="h2" className="text-center">{title}</Title>
        <Text as="p" className="text-center">{content}</Text>
      </Header>
      <Container>
        {reasons.map(r => {
          const { icon, text } = r;
          return (
            <Card key={icon} className="text-center">
              <IconWrapper>{iconSwitch(icon)}</IconWrapper>
              <p>{text}</p>
            </Card>
          );
        })}
      </Container>
    </>
  );
};

Reasons.propTypes = {
  reason: PropTypes.object.isRequired,
};

export default Reasons;
